.wrapper-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(10, 10vw);
    grid-template-rows: repeat(20, 10vw);
}

.box {
    display: flex;
    img {
        width: 100%;
        height: 100%;
    }
}
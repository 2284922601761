@mixin transition() {
    transition: display 1.5s ease-in;
}

@mixin ez-grid($z-index) {
    display: none;
    grid-template-columns: repeat(10, 10vw);
    grid-template-rows: repeat(20, 10vw);
    z-index: $z-index;
    bottom: 0;
    position: sticky;
    height: 100%;
    width: 100%;
}

.container {
    &--animation-layer {
        @include transition();
        position: sticky;
        z-index: 3;
        bottom: 0;
        display: none;
        justify-content: center;
        width: 100%;
        #animation-img {
            width: 300px;
            height: 300px;
            background: url("../assets/slack.png") left center;
            background-size: cover;
            animation: play 1.5s steps(2) infinite;
            transition-duration: 2s;
        }
        @keyframes play {
            100% {
                background-position: -600px;
            }
        }
    }
    &--image-layer {
        @include ez-grid(1);
        #image {
            background-size: cover;
            grid-row: 9 / span 4;
            grid-column: 7 / span 4;
        }
    }
    &--text-layer {
        @include ez-grid(4);
        .text {
            width: 100%;
            background-color: white;
            grid-column: 2 / span 8;
            grid-row: 3 / span 5;
            border: 1px dashed black;
            display: flex;
            align-items: center;
            h2 {
                color: black;
                text-align: center;
                margin: 0;
            }
        }
        .banner {
            width: 100%;
            padding-left: 8px;
            border-radius: 5%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: teal;
            grid-row: 15 / span 2;
            grid-column: 2 / span 7;
        }
    }
}
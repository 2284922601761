h1 {
    font-size: 2em;
    color: white;
    font-weight: 400;
    text-align: center;
    font-family: "Public Sans", sans-serif;
}

h2 {
    font-size: 1.5em;
    font-weight: 400;
    text-align: left;
    font-family: "Public Sans", sans-serif;
}

h3 {
    font-size: 0.8rem;
    color: white;
    font-weight: 700;
    text-align: left;
    font-family: "Public Sans", sans-serif;
}

p {
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    font-family: "Public Sans", sans-serif;
}
@import "./fonts",
"./breakpoints",
"./ez-grid-containers.scss",
"./wrapper-grid",
"./wrapper-talking-animation";
body {
    position: absolute;
    min-width: 100%;
    font: "Public Sans", sans-serif;
    min-height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    font-size: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.wrapper {
    width: 102vw;
    height: 100vh;
}